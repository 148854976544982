import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import CoolNavBar from './coolNavBar';
import CoolFooter from './coolFooter';
import Seo from '../seo';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';

const GlobalStyle = createGlobalStyle`
 * {
    box-sizing: border-box;
  }

  html {
    background-color: red;
  }

  body {
    margin: 0;
    background-color: lavenderblush;
    font-family: "Roboto",Helvetica,Arial,sans-serif;
  }
`;

const SiteMainDivStyled = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const ContentMainDivStyled = styled.div`
  flex-grow: 1;
`;

export default function LayoutWhite({ children }) {
  return (
    <React.Fragment>
      <Seo />
      <GlobalStyle />
      <SiteMainDivStyled>
        <CoolNavBar />
        <ContentMainDivStyled>{children}</ContentMainDivStyled>
        <CoolFooter />
      </SiteMainDivStyled>
    </React.Fragment>
  );
}
