import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: white;
  padding-bottom: 1.5rem 2rem;
  top: 0;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    justify-content: center;
    box-shadow: 0 2px 10px 0 lightgray;
    border: 1px solid lightgray;
    align-items: center;
    flex-direction: column;
  }
`;

const GatsbyLinkStyled = styled(GatsbyLink)`
  text-decoration: none;
  color: black;
  margin-left: 100px;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    margin-left: 0;
  }
`;

const GatsbyLinkStyledNav = styled(GatsbyLink)`
  text-decoration: none;
  color: black;
`;

const UnorganizedList = styled.ul`
  list-style: none;
  margin-right: 100px;
  padding-left: 0;

  @media screen and (min-device-width: 340px) and (max-device-width: 760px) {
    font-size: 18px;
    margin-right: 0;
    margin-top: 3px;
  }

  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
    font-size: 18px;
  }
`;

const ListItem = styled.li`
  display: inline-block;
  color: black;
  margin-left: 10px;
`;

const CoolNavBar = () => {
  return (
    <NavBar>
      <GatsbyLinkStyled to='/'>
        <p>Alonso Araya</p>
      </GatsbyLinkStyled>
      <UnorganizedList>
        <ListItem>
          <GatsbyLinkStyledNav to='/projects'>Projects</GatsbyLinkStyledNav>
        </ListItem>
        <ListItem>
          <GatsbyLinkStyledNav to='/blog'>Blog</GatsbyLinkStyledNav>
        </ListItem>
        <ListItem>
          <GatsbyLinkStyledNav to='/about'>About Me</GatsbyLinkStyledNav>
        </ListItem>
      </UnorganizedList>
    </NavBar>
  );
};

export default CoolNavBar;
