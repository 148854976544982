import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import LetterboxdIcon from '../../images/svg/letterboxd-icon-light.svg';
import LinkedInIcon from '../../images/svg/linkedin-icon.svg';
import GithubIcon from '../../images/svg/github-icon.svg';

const FooterStyle = styled.footer`
  background: #141618;
  position: relative;
  color: #fff;
  text-align: center;
  width: 100vw;
  padding: 50px 8vw 30px;
`;

const FooterHomeLinkDiv = styled.div`
  margin-bottom: 30px;
  font-size: 20px;
`;

const GatsbyLinkStyled = styled(GatsbyLink)`
  text-decoration: none;
  color: white;
`;

const WebsiteLinksDiv = styled.div`
  margin-bottom: 30px;
  font-size: 16px;
  opacity: 0.8;
  color: #b5b3b3;
`;

const GatsbyLinkStyledWithMarginRight = styled(GatsbyLink)`
  text-decoration: none;
  color: white;
  margin-right: 10px;
`;

const GatsbyLinkStyledWithMarginLeft = styled(GatsbyLink)`
  text-decoration: none;
  color: white;
  margin-left: 10px;
`;

const HorizontalRuleFooter = styled.hr`
  opacity: 0.1;
  margin-bottom: 30px;
`;

// const FooterDescription = styled.p`
//   margin-bottom: 35px;
//   opacity: 0.7;
//   color: #b5b3b3;
//   font-size: 18px;
// `;

const SocialLinksDiv = styled.div`
  margin-bottom: 20px;
`;

const GithubIconSvg = styled(GithubIcon)`
  margin-right: 40px;
  filter: invert(100%);
`;
const LinkedInIconSvg = styled(LinkedInIcon)`
  margin-right: 40px;
`;

const CoolFooter = () => {
  return (
    <FooterStyle>
      <FooterHomeLinkDiv>
        <GatsbyLinkStyled to='/'>Alonso Araya</GatsbyLinkStyled>
      </FooterHomeLinkDiv>
      <WebsiteLinksDiv>
        <GatsbyLinkStyledWithMarginRight to='/blog'>
          Blog
        </GatsbyLinkStyledWithMarginRight>
        <GatsbyLinkStyledWithMarginLeft to='/projects'>
          Projects
        </GatsbyLinkStyledWithMarginLeft>
        <GatsbyLinkStyledWithMarginLeft to='/about'>
          About Me
        </GatsbyLinkStyledWithMarginLeft>
      </WebsiteLinksDiv>
      <HorizontalRuleFooter />
      {/* <FooterDescription>Alonso Araya 2021</FooterDescription> */}
      <SocialLinksDiv>
        <a
          href='https://github.com/bojackhorseman0309'
          name='github'
          rel='noopener noreferrer'
          target='_blank'
        >
          <GithubIconSvg
            width={25}
            height={25}
            aria-labelledby='github'
            role='presentation'
          />
        </a>
        <a
          href='https://www.linkedin.com/in/alonso-araya-calvo-83a572168/'
          name='linkedin'
          rel='noopener noreferrer'
          target='_blank'
        >
          <LinkedInIconSvg
            width={25}
            height={25}
            aria-labelledby='linkedin'
            role='presentation'
          />
        </a>
        <a
          href='https://letterboxd.com/alonsoaraya/'
          name='letterboxd'
          rel='noopener noreferrer'
          target='_blank'
        >
          <LetterboxdIcon
            width={25}
            height={25}
            aria-labelledby='letterboxd'
            role='presentation'
          />
        </a>
      </SocialLinksDiv>
    </FooterStyle>
  );
};

export default CoolFooter;
